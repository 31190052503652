import { Popover } from "@headlessui/react";
import { Link, useTransition } from "@remix-run/react";
import { useState, useMemo } from "react";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { ClientOnly } from "remix-utils";
import { AutoLocation } from "~/components/organisms/AutoLocation/AutoLocation.client";
import { DialogModel } from "../organisms/dialogModel/DialogModel";
import { Slider } from "../organisms/slider/Slider";
import { SearchLottie } from "../organisms/SearchLottie";

export const RadiusData = [
  { value: "5" },
  { value: "10" },
  { value: "20" },
  { value: "30" },
  { value: "40" },
  { value: "50" },
  { value: "60" },
];

export const DummyComponentFallBack = () => <>Loading...</>;

/**
 * Truncates a string to a maximum length of 10 characters and adds '...' at the end
 * @param {string} string - The string to be truncated
 * @return {string} - The truncated string
 */
export const truncateString = (string: string): string => {
  if (string.length > 10) {
    return `${string.slice(0, 10)}...`;
  } else {
    return string;
  }
};

/**
 * Creates a URL string with jobTitle and location as query parameters
 * @param {string} baseUrl - The base URL to append query parameters to
 * @param {string} [jobTitle] - The job title to filter the search
 * @param {string} [location] - The location to filter the search
 * @return {string} - The URL string with query parameters
 */
function createUrl(
  baseUrl: string,
  jobTitle?: string,
  location?: string,
  radius?: string,
  latitude?: string,
  longitude?: string
): string {
  if (!baseUrl) return baseUrl;
  let url = new URL(baseUrl);
  if (jobTitle && jobTitle !== "Job Title")
    url.searchParams.append("jobTitle", jobTitle);
  if (location && location !== "Location") {
    url.searchParams.append("location", location);
    if (radius != "0") url.searchParams.append("radius", radius!);
  }
  if (latitude) {
    url.searchParams.append("lat", latitude);
  }
  if (longitude) {
    url.searchParams.append("lng", longitude);
  }

  return `/jobs${url.search}`;
}

export function JobLocation({
  googleApiKey,
  jobTitle,
}: {
  jobTitle: string;
  googleApiKey: string;
}) {
  const transition = useTransition();
  const [isOpen, setIsOpen] = useState(false);
  const [locationData, setLocationData] = useState("Location");
  const [radius, setRadius] = useState("15");

  const [location, setLocation] = useState<null | {
    value: { description: string };
  }>(null);
  const [latLng, setLatLng] = useState<null | { lat: number; lng: number }>(
    null
  );

  useMemo(() => {
    async function load() {
      if (location) {
        const [res] = await geocodeByAddress(location.value.description);
        const { lat, lng } = await getLatLng(res);
        setLatLng({ lat, lng });
      }
      return null;
    }
    load();

    if (location) setLocationData(location?.value.description);
  }, [location]);

  let baseUrl = "http://localhost:3000";

  const searchQueryParams = createUrl(
    baseUrl,
    jobTitle,
    locationData,
    radius,
    latLng?.lat === undefined ? "" : String(latLng?.lat),
    latLng?.lng === undefined? "" : String(latLng?.lng)
  );
  const isLocationChanging = Boolean(transition.location?.key);

  const searchIcon = isLocationChanging ? (
    <div className="flex justify-center items-center">
      <SearchLottie />
    </div>
  ) : (
    <svg
      data-testid="SAUK-mobile-search-toolbar-magnify-icon"
      width={40}
      height={40}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={23} height={23} rx="11.5" fill="#00A0DD" />
      <path
        d="M15.8125 15.8125L12.9375 12.9375M13.8958 10.5417C13.8958 12.3941 12.3941 13.8958 10.5417 13.8958C8.68921 13.8958 7.1875 12.3941 7.1875 10.5417C7.1875 8.68921 8.68921 7.1875 10.5417 7.1875C12.3941 7.1875 13.8958 8.68921 13.8958 10.5417Z"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div
      data-testid="SAUK-mobile-search-toolbar"
      className="mx-auto md:px-0 md:max-w-xl"
    >
      <div className="z-50 flex items-center justify-center w-full px-2 py-1 rounded-3xl bg-white">
        <div className="grid items-center w-full grid-cols-1 space-x-6 ">
          <div
            data-testid="SAUK-mobile-search-toolbar-location"
            className="col-span-2 "
          >
            <Popover className="relative  z-[40]">
              <Popover.Button
                className="hidden w-full text-left md:block focus-visible:outline-none ml-2"
                onClick={() => setIsOpen(false)}
              >
                <span className="text-sm font-bold text-black ">
                  {truncateString(locationData)}
                  {radius != "0" && (
                    <span className="text-sm font-bold text-black ">
                      , + {radius} miles
                    </span>
                  )}
                </span>
              </Popover.Button>
              <Popover.Button
                className="block w-full text-left md:hidden focus-visible:outline-none"
                onClick={() => setIsOpen(true)}
              >
                <span className="text-sm font-bold text-black ">
                  {truncateString(locationData)}
                  {radius != "0" && (
                    <span className="text-sm font-bold text-black ">
                      , + {radius} miles
                    </span>
                  )}
                </span>
              </Popover.Button>
              <Popover.Panel className="absolute z-10 top-10 -left-4 min-w-[271px] ">
                <div className="w-full px-6 pb-8 bg-white rounded-3xl border-1 border-staff-520">
                  <div className="w-full mt-10 mb-6 ">
                    <ClientOnly fallback={<DummyComponentFallBack />}>
                      {() => (
                        <AutoLocation
                          apiKey={googleApiKey}
                          setLocation={setLocation}
                        />
                      )}
                    </ClientOnly>
                  </div>
                  {locationData !== "Location" && (
                    <Slider
                      label="Radius"
                      state={setRadius}
                      radius={radius}
                      isMobile={false}
                    />
                  )}
                </div>
              </Popover.Panel>
            </Popover>
          </div>
        </div>

        <Link  to={searchQueryParams} className="col-span-1">
          {searchIcon}
        </Link>
      </div>

      <DialogModel
        data-testid="SAUK-mobile-search-dialog-model"
        className="absolute top-0 left-0"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div
          data-testid="SAUK-mobile-search-modal-location-field"
          className="my-10 mx-4"
        >
          <ClientOnly fallback={<DummyComponentFallBack />}>
            {() => (
              <AutoLocation apiKey={googleApiKey} setLocation={setLocation} />
            )}
          </ClientOnly>
        </div>
        <div
          data-testid="SAUK-mobile-search-modal-radius-slider"
          className="px-4 my-10"
        >
          {locationData !== "" && (
            <Slider
              label="Radius"
              state={setRadius}
              radius={radius}
              isMobile={true}
            />
          )}
        </div>

        <div>
          <div className="sticky bottom-0 w-full border-t-2 shadow-2xl shadow-slate-100 ">
            <div
              data-testid="SAUK-mobile-search-modal-toolbar"
              className="flex items-center justify-between p-5 "
            >
              <button
                disabled={
                  (jobTitle && jobTitle !== "Job Title") ||
                  locationData !== "Location"
                    ? false
                    : true
                }
                data-testid="SAUK-mobile-search-modal-toolbar-clear-btn"
                className={`px-3 py-2 text-sm font-semibold rounded-3xl first-line: ${
                  (jobTitle && jobTitle !== "Job Title") ||
                  locationData !== "Location"
                    ? "text-staff-200"
                    : "text-staff-150"
                }`}
                onClick={() => {
                  setLocationData("Location");
                }}
              >
                Clear all
              </button>
              <div></div>
              <Link
                to={searchQueryParams}
                className="px-3 py-2 text-sm font-semibold text-white bg-staff-200 rounded-3xl"
              >
                Search
              </Link>
            </div>
          </div>
        </div>
      </DialogModel>
    </div>
  );
}
